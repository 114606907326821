import { navigate } from 'gatsby'
import React from 'react'
import Revision from '../../assets/menu/icon_revision.svg'
import { t } from '../../../common/i18n'
import ImageRevision from '../../../specific/images/home/revision-cambio-de-aceite.png'
import route from '../../utils/route'
import CardHome from '../cardHome/CardHome'
import styles from './index.module.scss'

export default function RevisionCardMobileHome() {
  return (
    <CardHome
      icon={<Revision className={styles.revision_icon} />}
      title={t('home.revision_cambio_aceite')}
      textButton={t('home.elegir')}
      onClickButton={() => navigate(route('revisiones.asistente'))}>
      <div className={styles.image_wrapper}>
        <img
          width="270"
          height="80"
          src={ImageRevision}
          alt={t('home.revision_cambio_aceite')}
        />
      </div>
    </CardHome>
  )
}
