import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from './index.module.scss'

interface Props {
  starsFilled: number
  size?: 'big' | 'small'
}
export default function Stars({ starsFilled, size = 'small' }: Props) {
  return (
    <div className={size === 'big' ? styles.stars_big : styles.stars}>
      <FontAwesomeIcon
        className={starsFilled >= 1 ? styles.starFilled : styles.star}
        icon={faStar}
      />
      <FontAwesomeIcon
        className={starsFilled >= 2 ? styles.starFilled : styles.star}
        icon={faStar}
      />
      <FontAwesomeIcon
        className={starsFilled >= 3 ? styles.starFilled : styles.star}
        icon={faStar}
      />
      <FontAwesomeIcon
        className={starsFilled >= 4 ? styles.starFilled : styles.star}
        icon={faStar}
      />
      <FontAwesomeIcon
        className={starsFilled === 5 ? styles.starFilled : styles.star}
        icon={faStar}
      />
    </div>
  )
}
