import React from 'react'
import styles from './BlogPosts.module.scss'
import HomeSlider from './HomeSlider'
import { t } from '../../i18n'
import moment from 'moment'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

export interface BlogPost {
  date: string
  link: string
  title: string
  img: string
  localFile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

interface InnerProps {
  posts: BlogPost[]
  className: string
}

const BlogPostsInner = ({ posts, className }: InnerProps) => (
  <React.Fragment>
    {posts.map((p) => (
      <div key={p.link} className={`${className} ${styles.post}`}>
        <a href={p.link}>
          <GatsbyImage
            image={p.localFile?.childImageSharp?.gatsbyImageData}
            alt={p.title}
            loading={'lazy'}
          />
        </a>
        <div className={styles.fecha}>
          {moment(p.date).format('DD/MM/YYYY')}
        </div>
        <h3 dangerouslySetInnerHTML={{ __html: p.title }}></h3>
        <a href={p.link} className={styles.read_more}>
          {t('promociones.read_more')}
        </a>
      </div>
    ))}
  </React.Fragment>
)

interface Props {
  posts: BlogPost[]
}
const BlogPosts = ({ posts }: Props): JSX.Element => {
  // This is a switch that can be set in .env to hide the blog
  if (
    process.env.GATSBY_HIDE_BLOG_SECTION &&
    process.env.GATSBY_HIDE_BLOG_SECTION === 'true'
  ) {
    return null
  }

  return (
    <section className={styles.posts}>
      <h2>{t('home.ultimas_noticias')}</h2>
      <HomeSlider>
        {({ className }) => (
          <BlogPostsInner posts={posts} className={className} />
        )}
      </HomeSlider>
    </section>
  )
}

export default BlogPosts
