import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import Next from '../../assets/images/Talleres/next.svg'
import * as AltaVehiculoActions from '../../context/actions/altaVehiculoActions'
import * as RevisionActions from '../../context/actions/revisionActions'
import AppContext from '../../context/context'
import { t } from '../../i18n'
import route from '../../utils/route'
import Button from '../buttons/Button'
import FormError from '../FormError/FormError'
import InfoIcon from '../infoIcon/InfoIcon'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './index.module.scss'
import { matricula, normalizeMatricula } from '../../constants/matricula'
import { ValidateCP } from '../../../specific/helpers/validation/validationCP'
import { RevisionComingFrom } from '../../types/Revision'

const elpaso = process.env.GATSBY_WEB === 'elpaso'

const CarInfoDataFields = {
  ...matricula,
  kms: yup
    .number()
    .typeError(t('validations.kms.isNum'))
    .moreThan(0, t('validations.kms.moreThan'))
    .required(t('validations.kms.required')),
  postCode: yup
    .string()
    .test(
      'test-codigopostal',
      t('validations.form_facturacion.postalCode.invalid'),
      (cp) => ValidateCP(cp)
    ),
}

const carInfoFormSchema = yup.object().shape(CarInfoDataFields)

export interface MatriculaForm {
  matricula: string
  kms: boolean
  postCode: string
}

export default function RevisionHome() {
  const { register, handleSubmit, errors } = useForm<MatriculaForm>({
    validationSchema: carInfoFormSchema,
  })
  const {
    dispatch,
    altaVehiculoState,
    userInfo: { postCode },
  } = useContext(AppContext)

  const { loading } = altaVehiculoState

  const defaultPostCode = elpaso ? '35001' : ''

  const onSubmit = async (data) => {
    data.matricula = normalizeMatricula(data.matricula)
    const { matricula, kms, postCode } = data
    dispatch(RevisionActions.resetRevisiones())
    dispatch(RevisionActions.setRevisionComingFrom(RevisionComingFrom.HOME))
    dispatch(RevisionActions.setMatriculaDistance(matricula, kms, postCode))
    dispatch(AltaVehiculoActions.retrieveVehicleInfo(matricula))
  }

  if (!loading && altaVehiculoState.error) {
    dispatch(AltaVehiculoActions.resetAltaVehiculosError())
    dispatch(RevisionActions.setRevisionStep(2))
    dispatch(AltaVehiculoActions.setAltaVehiculoStep(5))
    navigate(route('revisiones.asistente'))
  }

  if (!loading && altaVehiculoState.vehicleInformation) {
    RevisionActions.setRevisionStep(2)
    navigate(route('revisiones.asistente'))
  }

  return (
    <div className={styles.revision_wrapper}>
      <div className={styles.title}>{t('revisiones.step1_titulo')}</div>
      {!loading ? (
        <form className={styles.form_car} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputs_container}>
            <div className={styles.matricula_cont}>
              <label className={styles.label_text}>
                {t('revisiones.step1_matricula')}
                <InfoIcon text={t('alta_vehiculo.tooltip_matricula')} />
              </label>
              <input
                name="matricula"
                ref={register}
                className={styles.inputForm_small}
                type="text"
              />
            </div>
            <div className={styles.kms_cont}>
              <label className={styles.label_text}>
                {t('revisiones.step1_kilometros')}
              </label>
              <input
                name="kms"
                ref={register}
                className={styles.inputForm_small}
                type="text"
              />
            </div>
            <div
              className={`${styles.kms_cont} ${
                postCode || elpaso ? styles.hide : ''
              }`}>
              <label className={styles.label_text}>
                {t('revisiones.step1_codigo_postal')}
              </label>
              <input
                name="postCode"
                ref={register}
                className={styles.inputForm_small}
                type="text"
                defaultValue={postCode || defaultPostCode}
              />
            </div>
          </div>

          <Button buttonClass="next_button" onClick={handleSubmit(onSubmit)}>
            {t('selector-desktop.continuar')}
            <Next />
          </Button>
        </form>
      ) : (
        <SpinnerWithText text={t('alta_vehiculo.buscando')} />
      )}
      {!loading ? (
        <div className={styles.matricula_info}>
          {t('alta_vehiculo.descripccion2')}
        </div>
      ) : null}
      <FormError errors={errors} field="matricula" />
      <FormError errors={errors} field="kms" />
    </div>
  )
}
