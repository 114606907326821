// import codigosPostales from '../../constants/cp'

const CP_REGEX = /^[0-9]{4}-[0-9]{3}$/

export const ValidateCP = function (cp) {
  if (cp.match(CP_REGEX)) {
    // const provinciaCP = codigosPostales.find(codigoPostal =>
    //   cp.startsWith(codigoPostal.cp)
    // )
    // if (provinciaCP) return true

    // For now in Portugal we only validate the format
    return true
  }
  return false
}
