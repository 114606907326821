import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import CardHome from '../../../../src/common/components/cardHome/CardHome'
import AppContext, { IAppState } from '../../../../src/common/context/context'
import { selectSkipInfo } from '../../context/selectors/selectorSelectors'
import { t } from '../../i18n'
import MotoIcon from '../../../../src/common/assets/images/family_icons/icon_moto.svg'
import {
  buscarPorMedidas,
  setOrigen,
} from '../../context/actions/selectorMotoActions'
import Step, {
  findNext,
  InSkipInfo,
} from '../FunnelSelectorMoto/SelectorMotoSteps'
import NeumaticoMotoMobile from '../NeumaticoMotoMobile/NeumaticoMotoMobile'
import styles from './index.module.scss'

interface Props {
  skipInfo: InSkipInfo
  dispatch: (action: any) => void
}
const SearchNeumaticosMotoCardHome = ({ skipInfo, dispatch }: Props) => {
  const { selectorMotoState } = useContext(AppContext)
  const onSubmit = async () => {
    dispatch(setOrigen(document.location.pathname))
    await buscarPorMedidas(selectorMotoState, dispatch)
    const next = findNext(Step.INITIAL, 'mobile', skipInfo, selectorMotoState)
    navigate(next.routes.mobile)
  }

  return (
    <CardHome
      icon={<MotoIcon className={styles.neumatico_icon} />}
      title={t('menuHeader.neumaticos_moto')}
      textButton={t('selector-desktop.buscar')}
      onClickButton={onSubmit}>
      <NeumaticoMotoMobile showDefaults={true} />
    </CardHome>
  )
}

export default connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(SearchNeumaticosMotoCardHome)
