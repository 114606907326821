const ffRevisionMoto = process.env.FF_REVISION_MOTO
const ffNeumaticosMoto = process.env.FF_LANDING_MODELOS_NEUMATICO_MOTO
const ffNeumaticoEV = process.env.FF_LANDING_NEUMATICOS_EV
const ffMichelin = process.env.FF_LANDING_MICHELIN
const ffBateria = process.env.FF_LANDING_BATERIA

const FEATURE_FLAGS = {
  REVISION_MOTO: ffRevisionMoto,
  NEUMATICOS_MOTO: ffNeumaticosMoto,
  NEUMATICOS_EV: ffNeumaticoEV,
  TUNEL_MICHELIN: ffMichelin,
  BATERIA: ffBateria,
}

const featuredPages = [
  {
    pathRegex: /landing-coche-electrico/,
    featureFlag: FEATURE_FLAGS.NEUMATICOS_EV,
  },
]
function isFeatureEnabled(feature) {
  return feature === 'true'
}

function isFeaturePageEnabled(pagePath) {
  const isDisabled = featuredPages.some(
    (page) =>
      new RegExp(page.pathRegex).test(pagePath) &&
      !isFeatureEnabled(page.featureFlag)
  )
  return !isDisabled
}

module.exports = {
  FEATURE_FLAGS,
  isFeatureEnabled,
  isFeaturePageEnabled,
}
