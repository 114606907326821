import React from 'react'
import { connect } from 'react-redux'
import { IAppState } from '../../../../context/context'
import {
  DEFAULT_VALUES_MEDIDAS_MOTO,
  MedidaName,
  SelectorMotoState,
} from '../../../../context/reducers/selectorMotoReducer'
import Neumatico from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/neumatico-moto.png'
import styles from './SVGMotoSelectorMedidas.module.scss'
import { selectSelectorMoto } from '../../../../context/selectors/selectorMotoSelectors'

interface Props {
  selectorMotoState: SelectorMotoState
  actual?: MedidaName
  showDefaults?: boolean
}

const SVGMotoSelectorMedidas: React.FC<Props> = ({
  selectorMotoState,
  actual,
  showDefaults = false,
}) => {
  return (
    <svg width="100%" height="133" viewBox="0 0 450 133">
      <image xlinkHref={Neumatico} width="100%" height="100%" />
      <path
        id="anchoPath"
        d="M 87 110 A 165 110 0 0 1 255 58"
        fill="none"
        stroke="none"
      />
      <path
        id="altoPath"
        d="M 270 61 A 165 110 0 0 1 323 80"
        fill="none"
        stroke="none"
      />
      <path
        id="llantaPath"
        d="M 332 86 A 165 110 0 0 1 376 122"
        fill="none"
        stroke="none"
      />
      <text className={styles.text}>
        <textPath
          className={`${
            !selectorMotoState.ancho.value && !showDefaults
              ? styles.textDefault
              : ''
          } ${actual === 'ancho' ? styles.parpadeo : ''}`}
          xlinkHref="#anchoPath"
          startOffset={'100%'}
          textAnchor={'end'}>
          {showDefaults || !selectorMotoState.ancho.value
            ? DEFAULT_VALUES_MEDIDAS_MOTO.ancho
            : selectorMotoState.ancho.value}
        </textPath>
        <textPath
          className={`${
            !selectorMotoState.alto.value && !showDefaults
              ? styles.textDefault
              : ''
          } ${actual === 'alto' ? styles.parpadeo : ''}`}
          xlinkHref="#altoPath"
          startOffset={'100%'}
          textAnchor={'end'}>
          {showDefaults || !selectorMotoState.alto.value
            ? DEFAULT_VALUES_MEDIDAS_MOTO.alto
            : selectorMotoState.alto.value}
        </textPath>
        <textPath
          className={`${
            !selectorMotoState.llanta.value && !showDefaults
              ? styles.textDefault
              : ''
          } ${actual === 'llanta' ? styles.parpadeo : ''}`}
          xlinkHref="#llantaPath"
          startOffset={'100%'}
          textAnchor={'end'}>
          {showDefaults || !selectorMotoState.llanta.value
            ? DEFAULT_VALUES_MEDIDAS_MOTO.llanta
            : selectorMotoState.llanta.value}
        </textPath>
      </text>
    </svg>
  )
}

const mapStateToProps = (state: IAppState) => ({
  selectorMotoState: selectSelectorMoto(state),
})
export default connect(mapStateToProps)(SVGMotoSelectorMedidas)
