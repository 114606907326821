import { Link } from 'gatsby'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { locale } from '../../../common/i18n'
import route from '../../utils/route'
import styles from './valoresRodi.module.scss'
import valores from '../../../specific/components/valores/valores'

interface ValorProps {
  icon: JSX.Element
  text_es?: JSX.Element
  text_ca?: JSX.Element
  text_pt?: JSX.Element
  link: string
}

const ValorRodi = ({ icon, text_es, text_ca, text_pt, link }: ValorProps) => {
  let text;
  switch (locale) {
    case 'pt':
      text = text_pt;
      break
    case 'ca':
      text = text_ca;
      break
    case 'es':
    default:
      text = text_es;
      break
  }
  return (
    <div className={styles.valor}>
      <Link to={route(link)}>{icon}</Link>
      {text}
    </div>
  )
}

const ValoresRodi = () => (
  <div className={styles.container}>
    <h2 className={styles.title}>
      <FormattedMessage id="home.confiar_taller" />
    </h2>
    {valores.map(valor => (
      <ValorRodi {...valor} key={valor.link} />
    ))}
  </div>
)

export default React.memo(ValoresRodi)
