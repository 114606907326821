import React from 'react'
import { MedidaName } from '../../context/reducers/selectorMotoReducer'
import styles from './neumaticoMobile.module.scss'
import SVGMotoSelectorMedidas from '../FunnelSelectorMoto/Common/SVGMotoSelectorMedidas/SVGMotoSelectorMedidas'

interface Props {
  actual?: MedidaName
  showDefaults?: boolean
}
const NeumaticoMotoMobile = ({ actual, showDefaults = false }: Props) => {
  return (
    <div className={styles.neumatico}>
      <div className={styles.neumatico_container}>
        <SVGMotoSelectorMedidas actual={actual} showDefaults={showDefaults} />
      </div>
    </div>
  )
}

export default NeumaticoMotoMobile
