import * as yup from 'yup'
import { t } from '../../common/i18n'

export const matricula = {
  matricula: yup
    .string()
    .matches(
      process.env.GATSBY_WEB === 'rodimotor'
        ? /^(([A-Za-z]{2}[-\s\.]*\d{2}[-\s\.]*(\d{2}|[A-Za-z]{2}))|(\d{2}[-\s\.]*(\d{2}[-\s\.]*[A-Za-z]{2}|[A-Za-z]{2}[-\s\.]*\d{2})))$/
        : /^([0-9]{4}[-\s\.]*[A-Za-z]{3}|[A-Za-z]{1,2}?[-\s\.]*[0-9]{4}[-\s\.]*[a-zA-Z]{1,2}?)$/,
      t('validations.license.format')
    )
    .required(t('validations.license.required')),
}

export function normalizeMatricula(matricula: string) {
  return matricula.toUpperCase().replace(/[^0-9A-Z]+/g, '')
}
