import React from 'react'
import Alerta from '../../../../src/common/assets/home/icon_home_alerta.svg'
import Calendario from '../../../../src/common/assets/home/icon_home_calendario.svg'
import Confianza from '../../../../src/common/assets/home/icon_home_confianza.svg'
import Neumatico from '../../../../src/common/assets/home/icon_home_neumatico.svg'
import Taller from '../../../../src/common/assets/home/icon_home_taller.svg'

const valoresText = {
  neumatico_pt: (
    <p>
      Especialistas em <strong>Pneus</strong> e Serviços de&nbsp;
      <strong>Manutenção</strong> ao Melhor Preço
    </p>
  ),
  calendario_pt: <p>Marcação Prévia</p>,
  taller_pt: <p>Grandes Oficinas Mecânicas</p>,
  alerta_pt: <p>Manutenção do seu veículo com alertas personalizados</p>,
  confianza_pt: <p>Atendimento Personalizado por Profissionais de Confiança</p>,
}

const valores = [
  {
    icon: <Neumatico />,
    text_pt: valoresText.neumatico_pt,
    link: 'neumaticos',
  },
  {
    icon: <Calendario />,
    text_pt: valoresText.calendario_pt,
    link: 'cita-previa.seleccion',
  },
  {
    icon: <Taller />,
    text_pt: valoresText.taller_pt,
    link: 'talleres',
  },
  {
    icon: <Alerta />,
    text_pt: valoresText.alerta_pt,
    link: 'register',
  },
  {
    icon: <Confianza />,
    text_pt: valoresText.confianza_pt,
    link: 'contacto',
  },
]

export default valores
