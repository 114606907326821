import { graphql, navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import Button from '../components/buttons/Button'
import BlogPosts, { BlogPost } from '../components/home/BlogPosts'
import Opiniones from '../../specific/components/home/Opiniones'
import Layout from '../components/Layout'
import MarcasLogo from '../components/MarcasLogo/MarcasLogo'
import RevisionCardMobileHome from '../components/revisionCardMobileHome/revisionCardMobileHome'
import SearchNeumaticos from '../components/searchNeumaticosCardHome'
import { SeoData } from '../types/Seo'
import TabsHome from '../components/tabsHome'
import ValoresRodi from '../components/ValoresRodi/ValoresRodi'
import { MenuPrincipal } from '../../specific/constants/menu'
import { resetCitaPrevia } from '../context/actions/citaPreviaActions'
import * as RevisionActions from '../context/actions/revisionActions'
import { reset } from '../context/actions/selectorActions'
import AppContext from '../context/context'
import { useSelectedMenu } from '../../specific/helpers/customHooks/useSelectedMenu'
import { locale, t } from '../i18n'
import { getImages } from '../utils/imagesS3'
import route from '../utils/route'
import styles from './index.module.scss'
import ResponsiveSliderBanner from '../components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import { getTarifa } from '../utils/getTarifa'
import SearcNeumaticosMotoCardHome from '../components/SearchNeumaticosMotoCardHome/SearcNeumaticosMotoCardHome'
import RevisionMotoCardMobileHome from '../components/revisionMotoCardMobileHome/revisionMotoCardMobileHome'
import { FEATURE_FLAGS, isFeatureEnabled } from '../utils/featureFlag'
import BateriasCardMobileHome from '../components/bateriasCardMobileHome/bateriasCardMobileHome'

const goToRegister = () => {
  navigate(route('register'))
}

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}

interface HomeProps {
  data: object
  desktop?: boolean
  tablet?: boolean
}

const Home = ({ data }: HomeProps) => {
  const [showBtn, setShowBtn] = useState<boolean>(false)
  useSelectedMenu(MenuPrincipal.HOME)
  const { userInfo } = useContext(AppContext)
  let tarifa
  if (userInfo.postCode) {
    tarifa = getTarifa(userInfo.postCode)
  }

  useEffect(() => {
    setShowBtn(!userInfo.loggedIn)
  }, [userInfo.loggedIn])
  // There are some specific banners
  // that only will be shown to Galicia clients
  const desktopBanners =
    tarifa === 'gal'
      ? getImages(
          data[`bannersDesktop_${locale}`].nodes,
          locale,
          placeholderImage
        )
      : getImages(
          data[`bannersDesktop_${locale}`].nodes,
          locale,
          placeholderImage
        ).filter((banner) => !banner.Key.includes('gal_'))

  const mobileBanners =
    tarifa === 'gal'
      ? getImages(
          data[`bannersMobile_${locale}`].nodes,
          locale,
          placeholderImage
        )
      : getImages(
          data[`bannersMobile_${locale}`].nodes,
          locale,
          placeholderImage
        ).filter((banner) => !banner.Key.includes('gal_'))

  return (
    <section className={styles.home_wrapper}>
      <ResponsiveSliderBanner
        sizesVariant="banner_home"
        desktopImages={desktopBanners}
        mobileImages={mobileBanners}
      />
      <TabsHome />
      <SearchNeumaticos />
      <RevisionCardMobileHome />
      {isFeatureEnabled(FEATURE_FLAGS.NEUMATICOS_MOTO) && (
        <SearcNeumaticosMotoCardHome />
      )}
      {isFeatureEnabled(FEATURE_FLAGS.REVISION_MOTO) && (
        <RevisionMotoCardMobileHome />
      )}
      {/*{isFeatureEnabled(FEATURE_FLAGS.BATERIA) && <BateriasCardMobileHome />}*/}
      <ValoresRodi />
      <div className={styles.button_wrapper}>
        {showBtn && (
          <Button buttonClass="next_button" onClick={goToRegister}>
            {t('home.registrarme')}
          </Button>
        )}
      </div>
      <MarcasLogo />
    </section>
  )
}

interface Props {
  data: {
    seoData: SeoData
    posts: {
      nodes: BlogPost[]
    }
  }
}

const IndexPage = ({ data }: Props) => {
  const { dispatch } = useContext(AppContext)
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        origen: '/',
        tipoVehiculo: null,
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        marca: null,
      })
    )
    dispatch({
      type: RevisionActions.CHANGE_REVISION_STEP,
      payload: { step: 1 },
    })
    dispatch(resetCitaPrevia())
  }, [])

  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      beforeFooter={() => (
        <div className={styles.outer}>
          <div className={styles.inner}>
            <Opiniones />
            <BlogPosts posts={data.posts.nodes} />
          </div>
        </div>
      )}>
      <Home data={data} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query homePageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    bannersDesktop_es: allS3Asset(
      filter: { Key: { glob: "home/banners/es/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    bannersMobile_es: allS3Asset(
      filter: { Key: { glob: "home/banners/es/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    bannersDesktop_ca: allS3Asset(
      filter: { Key: { glob: "home/banners/ca/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    bannersMobile_ca: allS3Asset(
      filter: { Key: { glob: "home/banners/ca/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    bannersDesktop_pt: allS3Asset(
      filter: { Key: { glob: "home/banners/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    bannersMobile_pt: allS3Asset(
      filter: { Key: { glob: "home/banners/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    posts: allWpPost(
      sort: { order: DESC, fields: date }
      limit: 4
      filter: { status: { eq: "publish" } }
    ) {
      nodes {
        link
        id
        img
        date
        title
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 923
              breakpoints: [250, 345, 768, 923]
              placeholder: BLURRED
              layout: CONSTRAINED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`
