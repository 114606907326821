import React from 'react'
import { t } from '../../../../sites/rodi/i18n'
import styles from './marcasLogo.module.scss'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import route from '../../utils/route'
import logos from '../../../specific/components/MarcasLogo/MarcasLogo'

const altImagen = (marca: string) =>
  `${t('footer.neumaticos').toLowerCase()} ${marca}`

const MarcasLogo: React.FC = () => (
  <div className={styles.marcas_logo}>
    <h2 className={styles.title}>
      <FormattedMessage id="home.mejores_marcas" />
    </h2>
    <div className={styles.marcas_container}>
      {logos.map((logo) => (
        <Link
          key={logo.marca}
          to={route('neumaticos.marca', { marca: logo.marca })}>
          <img
            width="122"
            height="48"
            loading="lazy"
            src={logo.src}
            alt={altImagen(logo.marca)}
            className={styles.logo}
          />
        </Link>
      ))}
    </div>
  </div>
)

export default MarcasLogo
