export const getTarifa = (postCode) => {
  // The difference in tarifa affects different regions
  // within the Rodi area, if it is El Paso it will not be needed
  if (process.env.GATSBY_WEB === 'elpaso') {
    return ''
  }
  // Usamos como controladores de Galicia los prefijos de las cuatro provincias
  const galleganCP = ['15', '27', '32', '36']
  if (postCode && galleganCP.includes(postCode.slice(0, 2))) {
    return 'gal'
  }
  return ''
}

export const isAndorraPostalCode = (postCode) => {
  return !!postCode && postCode.slice(0, 2).toString().toUpperCase() === 'AD'
}
