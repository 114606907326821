import { RouteComponentProps } from '@reach/router'
import {
  SelectorMotoState,
  Step,
  URLOrigen,
} from '../../context/reducers/selectorMotoReducer'
import { MinimalUserInfo } from '../../context/selectors/userSelectors'
import route from '../../../../src/common/utils/route'

export enum StepsInMobile {
  SELECT_ANCHO,
  SELECT_ALTO,
  SELECT_LLANTA,
  SELECT_MARCAS,
  INSERT_DATOS_LEAD,
  SHOW_RESULTADOS,
}

export enum StepsInDesktop {
  INITIAL = 1,
  SELECT_PREFERENCIAS,
  INSERT_DATOS_LEAD,
  SHOW_RESULTADOS,
}

export enum StepsFeaturesInMobile {
  SELECT_MARCA_MODELO,
  SELECT_CILINDRADA_MODELO,
  SELECT_MODELO_MODELO,
  SELECT_FABRICACION_MODELO,
  SELECT_APLICACION_MODELO,
  SELECT_MARCAS,
  INSERT_DATOS_LEAD,
  SHOW_RESULTADOS,
}

export const TOTAL_MOBILE_STEPS = 5
export const TOTAL_DESKTOP_STEPS = 4
export const TOTAL_MOBILE_STEPS_FEATURES = 7

export interface InSkipInfo extends MinimalUserInfo {
  marca: boolean
}

type IDevice = 'mobile' | 'desktop'

export interface InStepDetails {
  step: Step
  routes: {
    mobile?: string
    desktop?: string
  }
  replace: boolean
  skip?: (
    state: InSkipInfo,
    device: IDevice,
    selectorMotoState: SelectorMotoState
  ) => boolean
  isLoadable: (state: SelectorMotoState, info: InSkipInfo) => boolean
}

export interface SelectorProps {
  origin?: URLOrigen
  skipInfo?: InSkipInfo
  selectorMotoState: SelectorMotoState
  dispatch?: (action: any) => void
  showMarcas?: boolean
  handleChange: (e: any, name: string) => void
  handleSubmit: (e: any) => void
}

export const StepDetails = [
  {
    step: Step.INITIAL,
    routes: {
      desktop: route('selector-moto.medidas'),
      mobile: route('selector-moto.ancho'),
    },
    replace: false,
    isLoadable: () => true,
  },
  {
    step: Step.SELECT_ANCHO,
    routes: {
      mobile: route('selector-moto.ancho'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || !selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: () => true,
  },
  {
    step: Step.SELECT_ALTO,
    routes: {
      mobile: route('selector-moto.alto'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || !selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: (state: SelectorMotoState) => state.ancho.value !== null,
  },
  {
    step: Step.SELECT_LLANTA,
    routes: {
      mobile: route('selector-moto.llanta'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || !selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: (state: SelectorMotoState) => state.ancho.value !== null,
  },
  {
    step: Step.SELECT_MARCA_MODELO,
    routes: {
      mobile: route('selector-moto.modelo_moto.marca'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: () => true,
  },
  {
    step: Step.SELECT_CILINDRADA_MODELO,
    routes: {
      mobile: route('selector-moto.modelo_moto.cilindrada'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) =>
      state.selectorModelo.marca.value !== null,
  },
  {
    step: Step.SELECT_MODELO_MODELO,
    routes: {
      mobile: route('selector-moto.modelo_moto.modelo'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) =>
      state.selectorModelo.cilindrada.value !== null,
  },
  {
    step: Step.SELECT_FABRICACION_MODELO,
    routes: {
      mobile: route('selector-moto.modelo_moto.fabricacion'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) =>
      state.selectorModelo.modelo.value !== null,
  },
  {
    step: Step.SELECT_APLICACION_MODELO,
    routes: {
      mobile: route('selector-moto.modelo_moto.aplicacion'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return device === 'desktop' || selectorMotoState.buscar_por_medidas
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) =>
      state.selectorModelo.fabricacion.value !== null,
  },
  {
    step: Step.SELECT_MARCAS,
    routes: {
      mobile: route('selector-moto.marcas'),
    },
    skip: (info: InSkipInfo, device: IDevice) => {
      return device === 'desktop'
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_LLANTA].isLoadable(state, info) ||
      StepDetails[Step.SELECT_APLICACION_MODELO].isLoadable(state, info),
  },
  {
    step: Step.SELECT_PREFERENCIAS,
    routes: {
      desktop: route('selector-moto.preferencias'),
    },
    skip: (info: InSkipInfo, device: IDevice) => {
      return device === 'mobile'
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_LLANTA].isLoadable(state, info),
  },
  {
    step: Step.INSERT_DATOS_LEAD,
    routes: {
      mobile: route('selector-moto.datos'),
      desktop: route('selector-moto.datos'),
    },
    skip: (
      info: InSkipInfo,
      device: IDevice,
      selectorMotoState: SelectorMotoState
    ) => {
      return true
    },
    replace: true,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) => {
      return StepDetails[Step.INSERT_DATOS_LEAD - 1].isLoadable(state, info)
    },
  },
  {
    step: Step.SHOW_RESULTADOS,
    routes: {
      mobile: route('selector-moto.resultados'),
      desktop: route('selector-moto.resultados'),
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info: InSkipInfo) => {
      return (
        StepDetails[Step.SHOW_RESULTADOS - 1].isLoadable(state, info) &&
        StepDetails[Step.INSERT_DATOS_LEAD].skip(info, 'desktop', state)
      )
    },
  },
  {
    step: Step.SHOW_CAMARAS,
    routes: {
      mobile: route('selector-moto.resultados.camaras'),
      desktop: route('selector-moto.resultados.camaras'),
    },
    replace: false,
    isLoadable: (state: SelectorMotoState, info) =>
      StepDetails[Step.SHOW_RESULTADOS].isLoadable(state, info),
  },
] as InStepDetails[]

export function stepFromRoute(path: string) {
  for (let i = StepDetails.length - 1; i >= 0; i--) {
    const s = StepDetails[i]
    if (
      (s.routes.desktop && s.routes.desktop === path) ||
      (s.routes.mobile && s.routes.mobile === path)
    ) {
      return s
    }
  }

  return null
}

export function findPrevious(
  current: Step,
  device: 'desktop' | 'mobile',
  skipInfo: InSkipInfo,
  selectorMotoState: SelectorMotoState
) {
  for (let i = current - 1; i > 0; i--) {
    const details = StepDetails[i]
    if (
      details.routes[device] &&
      (!details.skip || !details.skip(skipInfo, device, selectorMotoState))
    ) {
      return details
    }
  }
  return StepDetails[Step.INITIAL]
}

export function findNext(
  current: Step,
  device: 'desktop' | 'mobile',
  skipInfo: InSkipInfo,
  selectorMotoState: SelectorMotoState
) {
  for (let i = current + 1; i < StepDetails.length; i++) {
    const details = StepDetails[i]
    if (
      details.routes[device] &&
      (!details.skip || !details.skip(skipInfo, device, selectorMotoState))
    ) {
      return details
    }
  }
  return StepDetails[Step.INITIAL]
}

export interface FunnelMotoScreenProps extends RouteComponentProps {
  nextStep: () => void
  previousStep: (useBrowserHistory?: boolean) => void
  dispatch: (action: any) => void
  selectorMotoState?: SelectorMotoState
}

export default Step
