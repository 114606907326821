import Michelin from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/michelin.svg'
import Pirelli from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/pirelli.svg'
import Continental from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/continental.svg'
import Goodyear from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/goodyear.svg'
import Dunlop from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/dunlop.svg'
import Bridgestone from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/bridgestone.svg'
import Firestone from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/firestone.svg'
import Falken from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/falken.svg'
import BfGoodrich from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/bfgoodrich.svg'
import Taurus from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/taurus.svg'
import Mabor from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/mabor.svg'
import Kleber from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/kleber.svg'
import Tigar from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/tigar.svg'
import Cooper from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/cooper.svg'

const logos = [
  {
    marca: 'michelin',
    src: Michelin,
  },
  {
    marca: 'continental',
    src: Continental,
  },
  {
    marca: 'pirelli',
    src: Pirelli,
  },
  {
    marca: 'goodyear',
    src: Goodyear,
  },
  {
    marca: 'dunlop',
    src: Dunlop,
  },
  {
    marca: 'bridgestone',
    src: Bridgestone,
  },

  {
    marca: 'kleber',
    src: Kleber,
  },
  {
    marca: 'mabor',
    src: Mabor,
  },

  {
    marca: 'falken',
    src: Falken,
  },

  {
    marca: 'bfgoodrich',
    src: BfGoodrich,
  },
  {
    marca: 'firestone',
    src: Firestone,
  },
  {
    marca: 'cooper',
    src: Cooper,
  },
  {
    marca: 'tigar',
    src: Tigar,
  },
  {
    marca: 'taurus',
    src: Taurus,
  },
]

export default logos
