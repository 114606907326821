import React from 'react'
import PropTypes from 'prop-types'
import ReactSwipe from 'react-swipe'
import styles from './HomeSlider.module.scss'

interface Props {
  children: (props: any) => JSX.Element | JSX.Element[]
}

const HomeSlider = ({ children }: Props) => (
  <ReactSwipe
    className={styles.container}
    style={{
      container: {},
      wrapper: {},
      child: {},
    }}
    swipeOptions={{
      auto: 4000,
      continuous: true,
      widthOfSiblingSlidePreview: 20,
    }}>
    {children({ className: styles.item })}
  </ReactSwipe>
)
HomeSlider.propTypes = {
  children: PropTypes.func.isRequired,
}

export default HomeSlider
